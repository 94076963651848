<template>
    <a-select
      show-search
      :value="displayValue"
      :placeholder="search_type === 'product' ? '搜索产品...' : '搜索物料...'"
      style="width: 200px"
      :default-active-first-option="false"
      :show-arrow="false"
      :filter-option="false"
      :not-found-content="loading ? '加载中...' : '无结果'"
      @search="handleSearch"
      @change="handleChange"
    >
      <a-select-option
        v-for="item in productOptions"
        :key="item.id"
        :value="item.id"
      >
        {{ item.name }} (ID: {{ item.id }})
      </a-select-option>
    </a-select>
  </template>
  
  <script>
  // 假设你在 '@/api/product.js' 有这么一个API
  // export function fetchProductList(params) { ... } // GET /api/products?search=xxx
  
  import { goodsInformationList } from '@/api/goods'
  import { materialsInformationList } from '@/api/materials'
  export default {
    name: 'RemoteSelect',
    props: {
    value: {
      type: Object,
      default: () => ({ id: null, name: '' })
    },
    search_type:{
      type: String,
      default: 'product'
    }
  },
    data() {
      return {
        productOptions: [],
        loading: false,
        searchTimer: null
      }
    },
    computed: {
      displayValue() {
      return this.value?.name || ''
    },
      loadingSlot() {
        // 自定义下拉中的 loading 显示
        // ant-design-vue 1.x 可以直接写jsx or return h(...)
        return <a-spin size="small" />;
      }
    },
    watch: {
      value(newVal) {
        // 保持外部传进来的value与内部的 selectedValue 同步
        this.selectedValue = newVal
      }
    },
    created() {
      this.selectedValue = this.value
    },
    methods: {
      handleSearch(keyword) {
        // 用户在下拉里输入搜索时触发
        if (!keyword) {
          // 若输入为空，可清空列表或加载默认列表
          this.productOptions = []
          return
        }
        // 防抖处理：避免用户快速输入时频繁请求
        if (this.searchTimer) clearTimeout(this.searchTimer)
        this.searchTimer = setTimeout(() => {
          this.searchProduct(keyword)
        }, 300)
      },
      async searchProduct(keyword) {
        this.loading = true
        let res;
        try {
          if(this.search_type=='product'){
            res = await goodsInformationList({ search: keyword })
          }else{
            res = await materialsInformationList({ search: keyword })
          }
          
          // 假设返回格式 { data: [{id, name}, ...] }
          this.productOptions = res.results || []
        } catch (e) {
          console.error(e)
          this.productOptions = []
        } finally {
          this.loading = false
        }
      },
      handleChange(selectedId) {
        // 用户选中了某个选项
        console.log('value',selectedId)
        // 根据选中的ID找到完整对象
      const selectedProduct = this.productOptions.find(
        item => item.id === selectedId
      ) || { id: selectedId, name: '' }

      // 触发父组件更新（传递完整对象）
      this.$emit('input', selectedProduct)
      this.$emit('change', selectedProduct)
      }
    }
  }
  </script>
  