<template>
  <a-modal
    :visible="visible"
    title="参数管理"
    @cancel="$emit('cancel')"
    :footer="modalFooter"
    destroyOnClose
    width="1000px"
  >
    <div style="margin-bottom: 16px">
      <a-button type="primary" @click="addParamRow">新增参数</a-button>
    </div>
    <a-table
      :dataSource="paramsData"
      :columns="columns"
      :rowKey="(record) => record.tempKey"
      bordered
      size="small"
    >
      <!-- 参数名称 -->
      <div slot="param_name" slot-scope="value, record">
        <a-select
          v-model="record.param_name"
          @change="handleParamNameChange(record, $event)"
          style="width: 100%"
          showSearch
          allowClear
        >
          <a-select-option
            v-for="param in params_info"
            :key="param.id"
            :value="param.param_name"
          >
            {{ param.param_name }}
          </a-select-option>
        </a-select>
      </div>
      <!-- 参数类型 -->
      <div slot="param_type" slot-scope="value, item" style="width: 100px">
        <a-select
          v-model="item.param_type"
          style="width: 100%"
          @change="handleParamTypeChange(item, $event)"
        >
          <a-select-option value="numeric">数值</a-select-option>
          <a-select-option value="text">文本</a-select-option>
          <a-select-option value="boolean" disabled="true"
            >布尔</a-select-option
          >
        </a-select>
      </div>
      <!-- 单位：当参数类型为 boolean 时禁用 -->
      <div slot="unit" slot-scope="value, item">
        <a-input
          v-model="item.unit"
          placeholder="单位"
          :disabled="item.param_type === 'boolean'"
        />
      </div>
      <!-- 下限：当参数类型为 boolean 时禁用 -->
      <div slot="lower_limit" slot-scope="value, item">
        <a-input-number
          v-model="item.lower_limit"
          style="width: 100%"
          :disabled="item.param_type === 'boolean'"
        />
      </div>
      <!-- 上限：当参数类型为 boolean 时禁用 -->
      <div slot="upper_limit" slot-scope="value, item">
        <a-input-number
          v-model="item.upper_limit"
          style="width: 100%"
          :disabled="item.param_type === 'boolean'"
        />
      </div>
      <!-- 期望值：当参数类型为 boolean 时显示布尔下拉框，否则显示普通输入框 -->
      <div slot="expected_value" slot-scope="value, item">
        <template v-if="item.param_type === 'boolean'">
          <a-select v-model="item.expected_value" style="width: 100%">
            <a-select-option :value="true">是</a-select-option>
            <a-select-option :value="false">否</a-select-option>
          </a-select>
        </template>
        <template v-else>
          <a-input v-model="item.expected_value" placeholder="期望值" />
        </template>
      </div>
      <!-- 参数说明 -->
      <div slot="description" slot-scope="value, item">
        <a-input v-model="item.description" placeholder="参数说明" />
      </div>
      <!-- 操作列 -->
      <div slot="action" slot-scope="value, item">
        <a-button size="small" type="danger" @click="deleteRow(item)"
          >删除</a-button
        >
      </div>
    </a-table>
  </a-modal>
</template>

<script>
import { productTestParamSync, materialTestParamSync } from "@/api/quantity";

export default {
  name: "TestStandardParamModal",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    standardId: {
      type: Number,
      required: true,
    },
    // 父组件传入当前检测规范下的初始参数列表
    initialParams: {
      type: Array,
      default: () => [],
    },
    // 用于区分产品或物料
    table_type: {
      type: String,
      default: "product",
    },
    params_info: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      paramsData: [], // 本地维护的最终参数列表
      isBatching: false,
    };
  },
  computed: {
    // 定义表格列（key 值用于匹配插槽）
    columns() {
      return [
        {
          title: "参数名称",
          dataIndex: "param_name",
          key: "param_name",
          width: "150px",
          scopedSlots: { customRender: "param_name" },
        },
        {
          title: "参数类型",
          dataIndex: "param_type",
          key: "param_type",
          width: "80px",
          scopedSlots: { customRender: "param_type" },
        },
        {
          title: "单位",
          dataIndex: "unit",
          key: "unit",
          scopedSlots: { customRender: "unit" },
        },
        {
          title: "下限",
          dataIndex: "lower_limit",
          key: "lower_limit",
          scopedSlots: { customRender: "lower_limit" },
        },
        {
          title: "上限",
          dataIndex: "upper_limit",
          key: "upper_limit",
          scopedSlots: { customRender: "upper_limit" },
        },
        {
          title: "期望值",
          dataIndex: "expected_value",
          key: "expected_value",
          scopedSlots: { customRender: "expected_value" },
        },
        {
          title: "参数说明",
          dataIndex: "description",
          key: "description",
          scopedSlots: { customRender: "description" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
          width: 100,
        },
      ];
    },
    // 自定义模态框底部区域
    modalFooter() {
      return [
        <a-button key="cancel" onClick={() => this.$emit("cancel")}>
          取消
        </a-button>,
        <a-button
          key="submit"
          type="primary"
          loading={this.isBatching}
          onClick={this.batchSubmit}
        >
          批量提交
        </a-button>,
      ];
    },
  },
  created() {
    this.initializeData();
  },
  watch: {
    initialParams: {
      handler(newVal) {
        this.initializeData();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initializeData() {
      // 将父组件传入的初始参数列表复制到本地，并添加一个临时唯一键（用于表格行 key）
      this.paramsData = this.initialParams.map((item) => {
        // 如果参数类型为 boolean，并且 expected_value 是字符串，进行转换
        if (
          item.param_type === "boolean" &&
          typeof item.expected_value === "string"
        ) {
          item.expected_value = item.expected_value.toLowerCase() === "true";
        }
        return {
          ...item,
          tempKey: "row_" + (item.id || Date.now() + Math.random()),
        };
      });
    },
    addParamRow() {
      // 新增一行空白记录
      const newRow = {
        id: null, // 无 id 表示待创建
        param_name: "",
        param_type: "numeric",
        unit: "",
        lower_limit: null,
        upper_limit: null,
        expected_value: "",
        description: "",
        standard: this.standardId,
        tempKey: "row_new_" + Date.now(),
      };
      this.paramsData.push(newRow);
    },
    deleteRow(record) {
      // 删除操作：直接从本地数组中移除记录
      this.paramsData = this.paramsData.filter(
        (x) => x.tempKey !== record.tempKey
      );
    },

    handleParamTypeChange(item, newType) {
      // 当类型切换时，清空不适用的字段
      item.unit = "";
      item.lower_limit = null;
      item.upper_limit = null;
      item.expected_value = null;
    },

    async batchSubmit() {
      this.isBatching = true;
      try {
        // 提交前过滤掉参数名称为空的项
        const validItems = this.paramsData.filter(
          (item) => item.param_name && item.param_name.trim() !== ""
        );
        const payload = {
          standard: this.standardId,
          items: validItems.map((item) => {
            const data = {
              param_name: item.param_name,
              param_type: item.param_type,
              unit: item.unit,
              lower_limit: item.lower_limit,
              upper_limit: item.upper_limit,
              expected_value: item.expected_value,
              description: item.description,
            };
            if (item.id) {
              data.id = item.id; // 有 id 的记录表示已有数据，需要更新
            }
            return data;
          }),
        };

        let res;
        if (this.table_type === "product") {
          res = await productTestParamSync(payload);
        } else {
          res = await materialTestParamSync(payload);
        }
        this.$message.success("批量提交成功");
        this.$emit("sync-success", res.data);
      } catch (error) {
        console.error(error);
        this.$message.error("批量提交失败");
      } finally {
        this.isBatching = false;
      }
    },

    handleParamNameChange(record, newVal) {
      // 检查当前 paramsData 中是否已有相同名称（排除当前记录）
      const duplicate = this.paramsData.find(
        (item) => item !== record && item.param_name === newVal
      );
      if (duplicate) {
        this.$message.error("参数名称不能重复");
        // 重置当前记录的参数名称及关联字段
        record.param_name = "";
        record.param_type = "numeric";
        record.unit = "";
        return;
      }

      // 根据选中的参数名称，从 params_info 中查找对应的记录
      const selected = this.params_info.find(
        (param) => param.param_name === newVal
      );
      if (selected) {
        record.param_type = selected.param_type;
        record.unit = selected.unit;
      } else {
        // 如果未匹配到，则可以设置默认值
        record.param_type = "numeric";
        record.unit = "";
      }
    },
  },
};
</script>

<style scoped>
/* 根据需要添加样式 */
</style>
