<template>
  <!-- 用 a-form-model 作为容器 -->
  <a-form-model
    ref="formRef"
    :model="form"
    :rules="rules"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 16 }"
  >
    <!-- 标准名称 -->
    <a-form-model-item label="标准名称" prop="name">
      <a-input v-model="form.name" placeholder="请输入名称" />
    </a-form-model-item>

    <!-- 测试类型 -->
    <a-form-model-item label="测试类型" prop="test_type">
      <a-select v-model="form.test_type">
        <a-select-option value="general">一般测试</a-select-option>
        <!-- 只有当 table_type 为 product 时才显示其他选项 -->
        <template v-if="table_type === 'product'">
          <a-select-option value="comprehensive">综合测试</a-select-option>
          <a-select-option value="installation">装机测试</a-select-option>
        </template>
      </a-select>
    </a-form-model-item>

    <!-- 产品 (示例用输入数字，也可换成自定义下拉) -->
    <a-form-model-item 
    :label="table_type === 'product' ? '产品' : '物料'"
    prop="product">
      <remote-select 
        v-model="form.product" 
        :search_type="table_type"
        style="width:100%"
      />
    </a-form-model-item>

    <!-- 描述 -->
    <a-form-model-item label="描述" prop="description">
      <a-textarea v-model="form.description" rows="3" />
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import RemoteSelect from '@/components/RemoteSelect/index.vue'
export default {
  name: 'TestStandardForm',
  components: {
    RemoteSelect
  },
  props: {
    // 父组件传进来的表单数据对象
    form: {
      type: Object,
      default: () => ({
        name: '',
        test_type: 'general',
        product: { id: null, name: '' }, // 改为对象结构
        description: ''
      })
    },
    table_type:{
      type: String,
    },
  },
  data() {
    return {
      // 用于本组件内部的双向绑定
      // 校验规则也可以由父组件传入，这里示例直接写在子组件内部
      rules: {
        name: [
          { required: true, message: '请填写标准名称', trigger: 'blur' }
        ],
        test_type: [
          { required: true, message: '请选择测试类型', trigger: 'change' }
        ],
        product: [
          { required: true, message: '请输入或选择产品', trigger: 'change' }
        ]
      }
    }
  },
 
  methods: {
    // 对外暴露一个校验方法，父组件可用 this.$refs.childRef.validate(...) 
    validate(callback) {
      this.$refs.formRef.validate(valid => {
        if (callback) callback(valid)
      })
    }
  }
}
</script>
