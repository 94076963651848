<template>
  <div class="test-standard-list-page">
    <a-card :tab-list="tabList" :active-tab-key="currentTab" @tabChange="changeTab">
      <a-row gutter="16">
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-input
            v-model="searchForm.search"
            placeholder="编号, 名称, 备注"
            allowClear
            @pressEnter="search"
          />
        </a-col>
        <!-- 仅当 currentTab 为 "product" 时显示测试类型筛选框 -->
        <a-col
          v-if="currentTab === 'product'"
          :span="24"
          style="max-width: 200px; margin-bottom: 12px"
        >
          <a-select
            placeholder="测试类型"
            v-model="searchForm.test_type"
            allowClear
            @change="search"
            style="width: 200px"
          >
            <a-select-option value="general">一般测试</a-select-option>
            <a-select-option value="comprehensive">综合测试</a-select-option>
            <a-select-option value="installation">装机测试</a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
        <div style="margin-bottom: 12px; float: right">
          <a-button
            type="primary"
            icon="plus"
            style="margin: 0 8px"
            @click="handleCreate"
          >
            新增检测规范
          </a-button>
        </div>
      </a-row>
      <a-row style="margin-top: 12px">
        <a-table
          :columns="columns"
          :dataSource="tableData"
          :rowKey="record => record.id"
          :loading="loading"
          bordered
        >
          <!-- 操作列插槽：旧版语法 -->
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button size="small" @click="handleEdit(item)">编辑</a-button>
              <a-button
                size="small"
                type="danger"
                style="margin-left: 4px"
                @click="handleDelete(item)"
              >
                删除
              </a-button>
            </a-button-group>
          </div>
          <!-- 参数管理列插槽：旧版语法 -->
          <div slot="param_action" slot-scope="value, item">
            <a-button size="small" style="margin-left: 4px" @click="handleManageParams(item)">
              参数管理
            </a-button>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <!-- 检测规范编辑/新增弹窗 -->
    <a-modal
      :visible="showModal"
      :title="editingId ? '编辑检测规范' : '新建检测规范'"
      @ok="handleOk"
      @cancel="showModal = false"
      :confirmLoading="confirmLoading"
      destroyOnClose
    >
      <!-- 使用 FormModel 的子组件 TestStandardForm 进行表单管理，
           同时传入当前 tab 以便区分产品与物料 -->
      <test-standard-form ref="formComp" :form="formData" :table_type="currentTab" />
    </a-modal>

    <!-- 参数管理模态框 -->
    <test-standard-param-modal
      :visible="showParamModal"
      :standardId="currentStandardId"
      :initialParams="currentStandardParams"
      :table_type="currentTab"
      :params_info="paramsInfo"
      @cancel="showParamModal = false"
      @sync-success="handleParamSyncSuccess"
    />
  </div>
</template>

<script>
import {
  productTestStandardList,
  productTestStandardCreate,
  productTestStandardUpdate,
  productTestStandardDestroy,
  materialTestStandardList,
  materialTestStandardCreate,
  materialTestStandardUpdate,
  materialTestStandardDestroy,


} from "@/api/quantity";
import {
  productParamsOption,
  materialParamsOption
} from "@/api/option";
import TestStandardForm from "./TestStandardForm.vue";
import TestStandardParamModal from "./TestStandardParamModal.vue";

export default {
  name: "TestStandardList",
  components: {
    TestStandardForm,
    TestStandardParamModal,
  },
  data() {
    return {
      // 当前激活的 Tab，'product' 或 'materials'
      currentTab: "product",
      tabList: [
        { key: "product", tab: "产品规范" },
        { key: "materials", tab: "物料规范" },
      ],
      // 搜索条件
      searchForm: { search: "", test_type: "", page: 1, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      typeList: [
        { id: 1, code: "general", name: "一般测试" },
        { id: 2, code: "comprehensive", name: "综合测试" },
        { id: 3, code: "installation", name: "装机测试" },
      ],
      tableData: [],
      // 编辑/新增弹窗
      showModal: false,
      confirmLoading: false,
      editingId: null,
      paramsInfo:[],
      formData: {
        name: "",
        test_type: "general",
        product: null, // 用于产品规范
        material: null, // 用于物料规范
        description: "",
      },
      // 参数管理模态框数据
      showParamModal: false,
      currentStandardId: null,
      currentStandardParams: [],
    };
  },
  computed: {
    columns() {
      if (this.currentTab === "product") {
        // 产品规范：显示产品名称和类型
        return [
          {
            title: "序号",
            dataIndex: "index",
            key: "index",
            customRender: (value, record, index) => index + 1,
            width: 45,
          },
          { title: "测试名称", dataIndex: "name" },
          { title: "产品名称", dataIndex: "product_name" },
          {
            title: "类型",
            dataIndex: "test_type",
            customRender: (text) => {
              const typeItem = this.typeList.find((item) => item.code === text);
              return typeItem ? typeItem.name : "未知";
            },
          },
          { title: "描述", dataIndex: "description" },
          {
            title: "操作",
            key: "action",
            scopedSlots: { customRender: "action" },
          },
          {
            title: "参数管理",
            key: "param_action",
            scopedSlots: { customRender: "param_action" },
          },
        ];
      } else {
        // 物料规范：不显示测试类型列，产品名称变成物料名称
        return [
          {
            title: "序号",
            dataIndex: "index",
            key: "index",
            customRender: (value, record, index) => index + 1,
            width: 45,
          },
          { title: "检测规范名称", dataIndex: "name" },
          { title: "物料名称", dataIndex: "material_name", customRender: (text, record) => {
              // 如果返回数据中有 material_name 则使用，否则 fallback 到 product_name
              return text || record.product_name || "";
            }
          },
          { title: "描述", dataIndex: "description" },
          {
            title: "操作",
            key: "action",
            scopedSlots: { customRender: "action" },
          },
          {
            title: "参数管理",
            key: "param_action",
            scopedSlots: { customRender: "param_action" },
          },
        ];
      }
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    changeTab(key) {
      this.currentTab = key;
      this.resetSearchForm();
      this.search();
    },
    resetSearchForm() {
      // 这里对于物料规范，可不显示 test_type 筛选条件
      this.searchForm = { search: "", test_type: this.currentTab === "product" ? "" : undefined, page: 1, page_size: 16 };
    },
    async fetchData() {
      this.loading = true;
      try {
        let res;
        if (this.currentTab === "product") {
          res = await productTestStandardList(this.searchForm);
        } else {
          res = await materialTestStandardList(this.searchForm);
        }
        this.tableData = res.results || [];
      } finally {
        this.loading = false;
      }
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.fetchData();
    },
    handleCreate() {
      this.editingId = null;
      this.formData = {
        name: "",
        test_type: "general",
        product: null,
        material: null,
        description: "",
      };
      this.showModal = true;
    },
    handleEdit(record) {
      this.editingId = record.id;
      // 编辑时根据 currentTab 分别处理
      if (this.currentTab === "product") {
        this.formData = {
          name: record.name || "",
          test_type: record.test_type || "general",
          product: record.product ? { id: record.product, name: record.product_name } : null,
          description: record.description || "",
        };
      } else {
        this.formData = {
          name: record.name || "",
          test_type: record.test_type || "general",
          material: record.material ? { id: record.material, name: record.material_name || record.product_name } : null,
          description: record.description || "",
        };
      }
      this.showModal = true;
    },
    handleDelete(record) {
      this.$confirm({
        title: "确认删除此检测规范吗？",
        onOk: () => {
          if (this.currentTab === "product") {
            return productTestStandardDestroy({ id: record.id }).then(() => {
              this.$message.success("删除成功");
              this.fetchData();
            });
          } else {
            return materialTestStandardDestroy({ id: record.id }).then(() => {
              this.$message.success("删除成功");
              this.fetchData();
            });
          }
        },
      });
    },
    handleManageParams(record) {
      // 打开参数管理模态框，传入当前检测规范ID和参数列表
      this.currentStandardId = record.id;
      this.currentStandardParams = record.params || [];

      if(this.currentTab=='product'){
        productParamsOption({ page_size: 999999,test_type:record.test_type }).then(
          (resp) => {
          this.paramsInfo = resp.results;
        }
        )
      }else{
        materialParamsOption({ page_size: 999999, }).then(
          (resp) => {
          this.paramsInfo = resp.results;
        }
        )
      }
      this.showParamModal = true;
    },
    
    handleOk() {
      // 调用子组件 TestStandardForm 的 validate 方法（使用 FormModel）
      this.$refs.formComp.validate((valid) => {
        if (!valid) {
          return;
        }
        this.confirmLoading = true;
        let payload = {
          ...this.formData,
        };
        if (this.currentTab === "product") {
          payload.product = this.formData.product ? this.formData.product.id : null;
        } else {
          payload.material = this.formData.product ? this.formData.product.id : null;
        }
        // console.log('formData',this.formData)
        // console.log('payload',payload)
        if (this.editingId) {
          payload.id = this.editingId;
          if (this.currentTab === "product") {
            productTestStandardUpdate(payload)
              .then(() => {
                this.$message.success("更新成功");
                this.showModal = false;
                this.fetchData();
              })
              .finally(() => {
                this.confirmLoading = false;
              });
          } else {
            materialTestStandardUpdate(payload)
              .then(() => {
                this.$message.success("更新成功");
                this.showModal = false;
                this.fetchData();
              })
              .finally(() => {
                this.confirmLoading = false;
              });
          }
        } else {
          if (this.currentTab === "product") {
            productTestStandardCreate(payload)
              .then(() => {
                this.$message.success("创建成功");
                this.showModal = false;
                this.fetchData();
              })
              .finally(() => {
                this.confirmLoading = false;
              });
          } else {
            materialTestStandardCreate(payload)
              .then(() => {
                this.$message.success("创建成功");
                this.showModal = false;
                this.fetchData();
              })
              .finally(() => {
                this.confirmLoading = false;
              });
          }
        }
      });
    },
    handleParamSyncSuccess(data) {
      this.$message.success("参数同步成功");
      this.showParamModal = false;
      this.fetchData();
    },
  },
};
</script>

<style scoped>
.test-standard-list-page {
  padding: 16px;
}
</style>
